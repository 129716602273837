@each $color, $value in $theme-colors {
  .btn-#{$color}:disabled {
    background-color: custom-color('inactive');
    border-color: custom-color('inactive');
    color: white;
    &:hover {
      background-color: custom-color('inactive');
      border-color: custom-color('inactive');
    }
  }
  .btn-#{$color}:hover,
  .show > .btn-#{$color}.dropdown-toggle {
    background-color: theme-color-level($color, -1.5);
    border-color: theme-color-level($color, -1.5);
  }

  .alert-#{$color} {
    color: theme-color($color);
    border-color: theme-color($color);
  }

  .text-#{$color} {
    color: theme-color($color);
  }

  .btn-#{$color}{
    background-color: var(--#{$color});
    border-color: var(--#{$color});
    &:hover{
      background-color: var(--light-#{$color});
      border-color: var(--light-#{$color});
    }
    &:not(:disabled):not(.disabled):active{
      background-color: var(--#{$color});
      border-color: var(--#{$color});

      &:focus {
        box-shadow: 0 0 0 0.2rem var(--light-#{$color});
      }
    }
    &:focus{
      background-color: var(--#{$color});
      border-color: var(--#{$color});
      box-shadow: 0 0 0 0.2rem var(--light-#{$color});
    }
  }

  .btn-outline-#{$color}{
    border-color: var(--#{$color});
    color: var(--#{$color}) !important;
    &:hover{
      background-color: var(--light-#{$color});
      border-color: var(--light-#{$color});
    }
    &:not(:disabled):not(.disabled):active{
      background-color: var(--#{$color});
      border-color: var(--#{$color});
      &:focus {
        box-shadow: 0 0 0 0.2rem var(--light-#{$color});
      }
    }
    &:not(:disabled):not(.disabled).active{
      background-color: var(--#{$color});
      border-color: var(--#{$color});
      &:focus {
        box-shadow: 0 0 0 0.2rem var(--light-#{$color});
      }
    }
    &:focus{
      box-shadow: 0 0 0 0.2rem var(--light-#{$color});
    }
  }
}

@each $color, $value in $custom-colors {
  .btn-#{$color}:disabled {
    background-color: custom-color('inactive');
    border-color: custom-color('inactive');
    color: white;
    &:hover {
      background-color: custom-color('inactive');
      border-color: custom-color('inactive');
    }
  }

  .btn-#{$color}{
    background-color: var(--#{$color});
    border-color: var(--#{$color});
    &:hover{
      background-color: var(--light-#{$color});
      border-color: var(--light-#{$color});
    }
    &:not(:disabled):not(.disabled):active{
      background-color: var(--#{$color});
      border-color: var(--#{$color});
    }
    &:focus{
      box-shadow: 0 0 0 0.2rem var(--light-#{$color});
    }
  }

  .btn-outline-#{$color}{
    border-color: var(--#{$color});
    &:hover{
      background-color: var(--light-#{$color});
      border-color: var(--light-#{$color});
    }
    &:not(:disabled):not(.disabled):active{
      background-color: var(--#{$color});
      border-color: var(--#{$color});
    }
    &:focus{
      box-shadow: 0 0 0 0.2rem var(--light-#{$color});
    }
  }
}

p {
  strong{
    font-family: "Helvetica Neue", Helvetica;
    font-weight: 700;
  }
}

.page-item {
  .page-link:hover {
    border-color: theme-color-level('primary', -2);
    background-color: theme-color-level('primary', -2);
    color: color-yiq(theme-color('primary'));
  }
}

/* general rules */
a, .btn-as-link {
  &.disabled {
    color: custom-color('inactive');
  }
  color: custom-color('link');
  overflow: hidden;
  text-decoration: none;
  &:hover {
    color: custom-color('link');
  }
}

//alerts

.alert-success{
    color: #386327;
    background-color: #e1f2db;
    border-color: #d6edcd;
}

.alert-danger {
  color: #853736;
  background-color: #ffe1e1;
  border-color: #ffd5d4;
}

.alert-dark{
  background: transparent;
}

body {
  // color: var(--alltexts);
  color: custom-color('alltexts');
  background-color: custom-color('background');
}

.table {
  thead th {
    background-color: theme-color('dark');
    color: color-yiq(theme-color('dark'));
  }

  &.table-custom-bordered {

    border-color: custom-color('lines');

    tr td {
      border-right-color: custom-color('lines');
    }

  }

  &.table-striped tbody tr:nth-of-type(even) {
    background-color: custom-color('tablestripped');
  }
  &.table-striped tbody tr:nth-of-type(odd) {
    background-color: white;
    --bs-table-accent-bg: #fff !important;
  }
}

i{
  &.success{
    color: theme-color('success')
  }
  &.danger {
    color: theme-color('danger')
  }
}

.gray-color{
  color: custom-color('text')!important;
}

.light-color{
  color: custom-color('lines')!important;
}

.dark-color{
  color: theme-color('dark')!important;
}

.border-color{
  border-color: theme-color('dark')!important;
}

/* forms */
input.form-control, select.form-control, textarea.form-control {
  border-color: custom-color('lines');
  // color: custom-color('input-filled');
  &:focus {
    border-color: theme-color('secondary');
  }
  &:invalid {
    border-color: theme-color('danger');
    color: theme-color('danger');
  }
}


/* mmp5-fieldset-haruki component */
mmp5-fieldset-haruki {
  .form-control {
    &:focus {
      border-color: theme-color('secondary');
    }
  }
  &.ng-touched {
    &.ng-invalid {
      input, select:not(.custom-select) {
        border-color: theme-color('danger');
        color: theme-color('danger');
      }
      .text-helper {
        color: theme-color('danger');
      }
    }
    // &.ng-valid {
    //   input, select {
    //     border-color: theme-color('secondary');
    //   }
    // }
  }
  .ng-clear {
    color: custom-color('text');
  }

  ng-select{
    &.ng-select-focused{
      border-color: theme-color('secondary');
    }
  }
}

.mat-mdc-checkbox:not(.mat-mdc-checkbox-disabled).mat-primary .mdc-checkbox__ripple .mat-ripple-element{
  background-color: var(--primary);
}

.mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple){
  background-color: var(--primary);
}

.mat-mdc-radio-button.mat-accent .mdc-radio__inner-circle{
  border-color: var(--primary) !important;
}

.mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mdc-radio__outer-circle{
  border-color: var(--primary);
}

.mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mat-radio-persistent-ripple{
  background-color: var(--primary);
}

.mat-mdc-radio-button {
  .mat-radio-label{
    .mat-radio-label-content{
      color: custom-color('text');
    }
  }
}
/* wizard steps form component */
mmp5-wizard-steps section > div {
  > div {
    background-color: white;
    border-color: custom-color('lines');
    &:after {
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-left-color: white;
    }

    &:before {
      border-top-color: transparent;
      border-bottom-color:transparent;
      border-left-color: custom-color('background');
    }
  }
  &.passed {
    color: #232327;
    >div {
      >div{
        >span::after{
          background: #515365!important;
        }
        >div::before{
          border: 2px solid #515365!important;
        }
      }
    }
  }
  &.active {
    color: var(--primary);
    &:after{
      background: #515365!important;
    }
    div {
      background-color: theme-color('dark');
      color: var(--primary);
      &:after {
        border-left-color: theme-color('dark');
      }
    }
  }
}

// mm-ui search-suggest

mmp5-search-suggest {
  input.form-control::placeholder{
    color: #888da8;
  }
}

/* mmp5-error-validation component colors */
mmp5-error-validation {
  color: theme-color('danger');
}

/* mmp5-form-submit-status component colors */
mmp5-form-submit-status {
  color: theme-color('danger');
}

/* mmp5-search-suggest component colors */
mmp5-search-suggest {
  .container-suggestions, .container-suggestions span  {
    border-color: custom-color('lines');
  }
  .container-suggestions span:hover {
    color: theme-color('primary');
  }
}

/* search suggest component */
mmp5-search-suggest {
  .container-suggestions {
    @include color-mm-shadow;
    border-color: custom-color('lines');
    background-color: white;
    span {
      border-bottom-color: custom-color('lines');
      &.active, &:hover {
        background-color: var(--primary);
        color: color-yiq(theme-color('primary'));
      }
    }
  }
}

/* alert component */
#alertComponent-container {
  @include color-mm-parent-container;
  h1 {
    @include color-mm-header-container;
  }
  > p, > div {
    @include color-mm-container;
  }
}

/* default box container */
.color-mm-box-container {
  @include color-mm-parent-container;
  .color-mm-box-header {
    @include color-mm-header-container;
  }
  .color-mm-box-content {
    @include color-mm-container;
  }
}


/* pagination */
.page-item {
  .page-link {
    border-color: custom-color('lines');
    &:hover {
      border-color: theme-color-level('primary', -2);
      background-color: theme-color-level('primary', -2);
      color: color-yiq(theme-color('primary'));
    }
  }
}


/* datepicker */
ngb-datepicker {
  border-color: custom-color('lines') !important;
  .bg-primary {
    background-color: var(--fourth) !important;
    border-top-color:transparent !important ;
  }

  &.dropdown {
    .ngb-dp-header {
      &:before {
        border-bottom-color: custom-color('lines');
      }
      &:after {
        border-bottom-color: white;
      }
    }
  }

}


.ruleview__field{
  color: theme-color('danger');
}

.ruleview__condition{
  color: theme-color('secondary');
}

.ruleview__value{
  color: theme-color('info');
}

/* ng select */
ng-select {

  &.form-control {
    border-color: custom-color('lines');
    .ng-dropdown-panel-items {
      background-color: white;
      border-color: custom-color('lines');
      max-height: 240px;
    }

    // input, .ng-value, .ng-clear {
    //   // color: custom-color('input-filled');
    // }

    .ng-option {

      &:hover, &.marked, &.ng-option-marked {
        background-color: var(--primary);
        color: color-yiq(theme-color('primary'));
      }
    }

  }

}

/* file drop */
.fieldset--haruki-filearea-filedrop {
  .content {
    color: custom-color('text') !important;
  }

  &.over {
    background-color: custom-color('background') !important;
    opacity: 0.5;
  }
}

/* collapses */
.collapsable {
  border-color: custom-color('lines');;
  .collapsable-header {
    border-color: custom-color('lines');;
  }
}

.mm-box-container {
  &.dark{
    background-color: custom-color('lines');
    border: 1px solid custom-color('lines');
  }
}
