@import "./assets/color-var.scss";

@import "../../mm-ui-app/src/_scss/mixins";


$spacer: 3rem;
@import "../../../node_modules/bootstrap/scss/bootstrap-reboot";
@import "../../../node_modules/bootstrap/scss/bootstrap-grid";
@import "../../../node_modules/bootstrap/scss/bootstrap-utilities.scss";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/tables";
@import "../../../node_modules/bootstrap/scss/breadcrumb";
@import "../../../node_modules/bootstrap/scss/pagination";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/navbar";
@import "../../../node_modules/bootstrap/scss/alert";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/buttons";
@import "../../../node_modules/bootstrap/scss/button-group";
// @import "../../../node_modules/bootstrap/scss/input-group";
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/progress";
@import "../../../node_modules/bootstrap/scss/modal";
@import "../../../node_modules/bootstrap/scss/carousel";
@import "../../../node_modules/bootstrap/scss/list-group";
@import "../../../node_modules/bootstrap/scss/dropdown";


//api material cdk overlay

@import "@angular/cdk/overlay-prebuilt.css";

@import "../../mm-ui-app/src/_scss/layout";
@import "../../mm-ui-app/src/assets/custom_color";

/* custom project */
@import "_scss/custom";

:root{
    --page-menu-size: 72px;
}

.text-link{
    color: #007ad9!important;
    text-decoration: none!important;
    cursor: pointer;

    &:hover{
        color: #116fbf!important;
    }
  }


.mdc-label {
    white-space: normal;
}

.table.table-custom-bordered th {
    background-color: var(--dark);
}

.mmp-nav-margin{
    margin-top: 7rem;
    min-height: 100vh;
}

.mat-tree-node, .mat-mdc-tab-group, .mat-tab-label {
    font-family: "Helvetica Neue", Helvetica;
}

@media(max-width:990px){
    .mmp-nav-margin {
        margin-top: 6rem;
    }
}

@font-face {
    font-family: "Helvetica Light";
    src: url("./assets/fonts/helvlig.ttf"),
}

@font-face {
    font-family: "Helvetica Neue";
    src: url("./assets/fonts/Helvetica-Neue.ttf") format("truetype"),
}

// @font-face {
//     font-family: "bakbak";
//     src: url("./assets/fonts/BakbakOne-Regular.ttf") format("truetype"),
// }

body {
    font-family:"Helvetica Neue", Helvetica;
    .yellow{
        height: 100%;
        width: 100%;
        position: absolute;
        // background: url('/assets/images/green.png'), url('/assets/images/background'+4+'.jpg');
        background-size: cover !important;
    }
    .purple {
        height: 100%;
        width: 100%;
        position: absolute;
        background: url('./assets/images/blue.png'), url('./assets/images/background5.jpg');
        background-size: cover;
    }
    .error {
        background-color:#a8dcf0;
        position: absolute;
        overflow: hidden;
        width: 100vw;
        height: 100vh;
        margin-top:0!important;
    }
}
.btn{
    padding: 1.2rem 1.66rem;
}

strong {
    font-weight: 700;
}

p, pre{
    font-family:"Helvetica Neue", Helvetica;
    font-weight: 500;
    font-size: 13px;
    color: var(--alltexts);
}

pre {
    word-break: break-all;
}

table {
    p{
        color: var(--alltexts);
    }
}

.mat-expansion-panel-header-title span,
.mat-expansion-panel-header-title h4,
.mat-expansion-panel-header-title h5.mb-0,
a.nav-link.active,
p.homepage-title {
    color: var(--alltexts) !important;
}

.nav-tabs .nav-link {
    cursor: pointer;
}

*.subtitle{
    font-family:"Helvetica Neue", Helvetica;
    font-weight: 700;
    color: map-get($theme-colors, 'dark')
}

hr, hr:not([size]){
    display: block;
    overflow: hidden;
    border-style: inset;
    border:0;
    border-top: 1px solid var(--lines)!important;
    top:0;
    box-sizing: content-box;
    margin:0;
    height: 0;
 }
 .dark-box{
    background-color: #e6ecf5;
    padding: 10px;
    color: #232327;
    border: 1px solid #e6ecf5;
    border-radius: 0.25rem;
 }

.widget{
    .mm-box-content{
        padding:1rem;
    }
}

iframe.tagManager {
    display: none;
    visibility: hidden;
}

.mat-nested-tree-node .mat-mdc-icon-button {
    font-size: 12px !important;
}

.content-page-list .mat-tree-node {
    padding-top: 1rem;
}

.content-page-list .mat-tree {
    background: white;
}

.aling-right {
    text-align: right;
    @media screen and (max-width: 450px) {
        text-align: left;
        margin-top: 10px;
    }
}

.p-sidebar {
    z-index: 1000 !important;
}

@media (max-width: 768px) {
    fab-page-homepages {
        display: none;
    }
}

.mdc-checkbox__background {
  width: 16px!important;
  height: 16px!important;
  top: 0px!important;
  left: -2px!important;
}

.mdc-checkbox__checkmark {
  width: 95%!important;
}

// change ckeditor text-color picker to fixed when showing a mat-modal
.cdk-overlay-container:has(div) ~ .cke_panel {
  position: fixed!important
}
.pull-left {
  float:left
}
.pull-right {
  float:right
}
.ml-1{
  margin-left: 0.75rem;
}
.ml-2{
  margin-left: 1.5rem;
}
.mr-1{
  margin-right: 0.75rem;
}
.mr-2{
  margin-right: 1.5rem;
}
