html,
body {
  font-size: 13px;
}

form.form-open {
  width: 100%;
  box-sizing: border-box;
  margin-left: 0;
}

.mt-1-m {
  text-align: right;
}

@media screen and (max-width: 450px) {
  .btn,
  .mm-box-header,
  .mm-box-header,
  .config-form,
  .config-header,
  .mm-box-footer,
  .table th,
  .table td {
    padding: 1rem !important;
  }
  .td_value {
    line-height: 1rem !important;
  }
  .mt-1-m {
    text-align: left;
    margin-top: 10px;
  }

  .remove-d-flex-mobile {
    display: block !important;
  }

  thead th {
    vertical-align: middle;
  }
}

@media screen and (max-width: 770px) and (min-width:450px) {
  .max-min-btn{
    font-size: 0.9rem;
  }
  .max-min-btn-2{
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1460px) and (min-width: 1200px) {
  .mmp-nav-margin .container,
  .mmp-nav-margin.container,
  .navbar .container,
  .container.large {
    max-width: 1140px;
  }

}

@media screen and (max-width: 1460px) and (min-width: 1000px) {
  .max-min-btn{
    font-size: 0.9rem;
  }
  .max-min-btn-2{
    font-size: 0.8rem;
  }
}

::ng-deep {
  @media screen and (max-width: 450px) {
    .alert-info {
      margin: 10px 0px !important;
    }
  }
}
